import { ref, onMounted, onUnmounted } from "vue";
import { defineStore } from 'pinia';

import useCredStore from "./store.js"
import { version } from '../../../../package.json';

import { 
	getCryptoStatus,
	postCryptoInit,
	postCryptoReset,
  postCryptoSession,
} from './apiCrypto.js';


export default defineStore('crypto', () => {
	
	const cryptoStatus = ref(null);
	const sn = ref("");
	
	const hash = ref();
	const hashError = ref();
	const time = ref();

	async function checkCryptoStatus() {
		const {transport, url} = getCryptoStatus;
		const { status } = await transport(url);
		cryptoStatus.value = status;
	};
	
	async function cryptoInit (psw){
		const {transport, url} = postCryptoInit;
		const res = await transport(url, psw);
		const credStore = useCredStore();
		credStore.setCredentials(res);
		return res;
	};

	async function cryptoSession (obj){
		const {transport, url} = postCryptoSession;
		const res = await transport(url, obj);
		const credStore = useCredStore();
		credStore.setCredentials(res);
		return res;
	};

	async function cryptoReset(){
		const {transport, url} = postCryptoReset;
		const res = await transport(url);
		sn.value = String(res?.id);
		hash.value = res?.hash
		hashError.value = Boolean(res.hashError);
		time.value = String(res?.time);
		return res;
	};	


	let pollingTimer;

	onMounted( async () => {
		await checkCryptoStatus();
		pollingTimer = setInterval(async () => {
			checkCryptoStatus();
		}, 2000);
	});

	onUnmounted( () => {
		clearInterval(pollingTimer);
	});


	return { 
		sn,
		version,
		cryptoStatus, 
		cryptoInit, 
		cryptoSession, 
		cryptoReset,
		time,
		hash,
		hashError, 
	};
});

