import Http from '@/common/utils/Http.js';


export const getCryptoStatus = {
	transport: Http.get,
	url: 'crypto/status'
}

export const postCryptoInit = {
	transport: Http.post,
	url: 'crypto/init'
}

export const postCryptoSession = {
	transport: Http.post,
	url: 'crypto/session'
}

export const postCryptoReset = {
	transport: Http.post,
	url: 'crypto/reset'
}